.gform_wrapper ul {
  @extend .list-unstyled;
  padding-left: 0!important;
}
.gform_wrapper li {
  @extend .form-group;
  padding-left: 0!important;
}
.gform_wrapper h1 {
  margin-bottom: 7px;
}
.gform_wrapper form {
  margin-bottom: 0;
}
.gform_wrapper .gfield_label {
  display: none;
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $state-danger-text;
}
.page-template-template-facility-php,
.page-template-template-contact-php {
  .gform_footer {
    display: none;
  }
}
.ginput_container input[type='checkbox']:not(old) {
  width   : 29px;
  height  : 0!important;
  margin  : 0;
  padding : 0;
  opacity : 0;
}
.gform_wrapper #choice_1_5_1 .gfield_label {
  display: block;
}
.ginput_container input[type=checkbox]:not(old) + label > span {
  display          : inline-block;
  width            : 25px;
  height           : 25px;
  margin-right     : 5px;
  border           : 4px solid $brand-primary;
  border-radius    : 0;
  background       : #fff;
  vertical-align   : middle;
}

.ginput_container input[type=checkbox]:not(old):checked + label > span:before{
  content     : '✓';
  display     : block;
  width       : 18px;
  color       : $brand-primary;
  font-size   : 1em;
  line-height : 1em;
  text-align  : center;
  font-weight : bold;
}

.ginput_container .gfield_checkbox {
  list-style: none!important;
  label {
    color: $brand-primary;
    font-weight: 300;
  }
}
.ginput_container input {
  height: 47px;
}
.ginput_container input,
.ginput_container select,
.ginput_container textarea {
	@extend .form-control;
    border-width: 4px;
}
.ginput_container textarea {
  height: auto;
}
.gform_button {
	@extend .btn;
	@extend .btn-primary;
}
.gform_wrapper .gfield_error {
  .gfield_label {
    color: $state-danger-text;
  }
  input,
  select,
  textarea {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
    @include form-control-focus($alert-danger-text);
  }
}
.validation_error {
	@extend .alert;
	@extend .alert-danger;
}
#gforms_confirmation_message {
	@extend .alert;
	@extend .alert-success;
}

#mc_embed_signup_scroll {
  .indicates-required {
    display: none;
  }
  #mc-embedded-subscribe {
   margin-top: 1em;
  }
  .mc-field-group {
    margin-bottom: 1em;
    display: block;
    input {
      height: 47px;
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.42857;
      color: #555;
      background-color: #FFF;
      background-image: none;
      border: 4px solid #2B3E99;
      border-radius: 0px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
      transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    }
    .phonefield {
      .phonearea,
      .phonedetail1,
      .phonedetail2 {
        display: inline-block;
        input {
          width: auto;
          display: inline-block;
          margin: 0 5px;
          text-align: center;
        }
      }
    }
  }
}