.logged-in .navbar {
  top: 32px;
}
.navbar {
  background-color: #fff;
  border: none;
  margin-bottom: 0;
  border-bottom: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  @media (min-width: $screen-sm-min) {
    position: relative;
  }
  & > .container {
    position: relative;
    z-index: 5;
  }
  .navbar-toggle {
    border-radius: 0;
    border-width: 4px;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 13px 12px;
    &:hover, &:active, &:focus {
      .icon-bar {
        background-color: $brand-primary;
      }
    }
    .icon-bar {
      height: 4px;
      width: 32px;
    }
    .icon-bar + .icon-bar {
      margin-top: 8px;
    }
  }
  .navbar-brand {
    height: auto;
    @media (min-width: $screen-sm-min) {
      height: auto;
      & > img {
        width: 100%;
        height: auto;
        display: inline-block;
      }
      max-width: 120px;
      margin-top: 9.6px;
      margin-bottom: 14.4px;
    }
    @media (min-width: $screen-md-min) {
      height: auto;
      & > img {
        width: 100%;
        height: auto;
        display: inline-block;
      }
      max-width: 150px;
      margin-top: 12px;
      margin-bottom: 18px;
    }
    @media (min-width: $screen-lg-min) {
      height: auto;
      & > img {
        width: 100%;
        height: auto;
        display: inline-block;
      }
      max-width: 190px;
      margin-top: 15px;
      margin-bottom: 23px;
    }
    @media (max-width: $screen-xs-max) {
      & > img {
        width: 74px;
        height: auto;
      }
      padding: 13px 15px;
      padding-right: 4px;
      padding-left: 21px;
    }
  }
  .navbar-title {
    float: left;
    a {
      text-decoration: none;
      &:hover,&:active,&:focus {
        color: $brand-primary;
      }
    }
    #site-title {
      color: $brand-primary;
      font-weight: 700;
      @media (min-width: $screen-sm-min) {
        font-size: 21.6px;
        margin-top: 36px;
        line-height: 0.76;
      }
      @media (min-width: $screen-md-min) {
        font-size: 27px;
        margin-top: 40px;
        line-height: 0.95;
      }
      @media (min-width: $screen-lg-min) {
        font-size: 34px;
        margin-top: 43px;
        line-height: 1.2;
      }
      @media (max-width: $screen-xs-max) {
        /* font-size: 16px; */
        font-size: 12px;
        margin-top: 22px;
      }
    }
    #site-tagline {
      color: $brand-primary;
      font-style: italic;
      font-weight: 700;
      @media (min-width: $screen-sm-min) {
        font-size: 13.6px;
        margin-left: 20.8px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 17px;
        margin-left: 26px;
      }
      @media (min-width: $screen-lg-min) {
        font-size: 21px;
        margin-left: 33px;
      }
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
  }
  .navbar-header {
    @media (min-width: $screen-sm-min) {
    width: 100%;
    }
  }
  #header-util {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    float: right;
    .column {
      width: 185px;
      float: right;
      margin-right: 50px;
      margin-top: 25px;
      @media (max-width: $screen-sm-max) {
        margin-top: 12px;
      }
      .header-contact {
        text-align: center;
        span {
          display: block;
          color: #646464;
          font-size: 15px;
          @media (max-width: $screen-sm-max) {
            font-size: 80%;
            line-height: 1.2;
          }
          a {
            color: #646464;
            text-decoration: none;
          }
        }
      }
      .search-form {
        border: 2px solid $brand-primary;
        border-radius: 0;
        margin-top: 12px;
        @media (max-width: $screen-sm-max) {
          margin-top: 8px;
        }
        .search-submit {
          color: $brand-primary;
          background-color: #fff;
          border-color: transparent;
          box-shadow: none!important;
          padding: 0 9px;
          margin-top: 3px;
          @media (max-width: $screen-sm-max) {
            padding: 0px 8px;
          }
        }
        .search-field {
          border: none;
          box-shadow: none!important;
          color: $brand-primary;
          padding-left: 0;
          &::-webkit-input-placeholder {
             color: $brand-primary;
          }
          &:-moz-placeholder { /* Firefox 18- */
             color: $brand-primary;
          }
          &::-moz-placeholder {  /* Firefox 19+ */
             color: $brand-primary;
          }
          &:-ms-input-placeholder {
             color: $brand-primary;
          }
          @media (max-width: $screen-sm-max) {
            font-size: 80%;
            padding: 0px 8px;
          }
        }
      }
    }
    #iso-9001 {
      width: 68px;
      height: 138px;
      margin-bottom: 7px;
      @media (max-width: $screen-sm-max) {
        width: 54.4px;
        height: 110.4px;
      }
      float: right;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .navbar-collapse {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    min-height: 59px;
    @media (max-width: $screen-xs-max) {
      border-top-width: 0;
      background-color: #fff;
      .dropdown-menu {
        background-color: $brand-primary;
      }
    }
    @media (min-width: $screen-sm-min) {
      .nav > li {
          &:first-child a {
            padding-left: 0;
          }
          &:last-child a {
            padding-right: 0;
          }
      }
    }
    .nav > li > a {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 15px;
      @media (min-width: $screen-md-min) {
        padding-left: 22px;
        padding-right: 22px;
      }
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: 12px;
      }
    }
    @media (max-width: $screen-xs-max) {
      .navbar-nav {
        .menu-item a {
          font-size: 18px;
          padding-top: 18px;
          padding-bottom: 18px;
        }
        & > li {
         &.dropdown:focus > .dropdown-toggle,
         &.dropdown:hover > .dropdown-toggle,
         &.dropdown:active > .dropdown-toggle,
         &.dropdown.open > .dropdown-toggle {
            background-image: url('../images/dropdown-selector.png');
            background-repeat: no-repeat;
            background-position: 20px bottom;
            background-size: 22px 8px;
          }
          & > .dropdown-menu {
            border: 0;
            @include clearfix;
            border-radius: 0;
            margin-left: -15px;
            margin-right: -15px;
            padding: 20px 15px;
            & > li {
              img.menu-thumb {
                height: auto;
                width: 75%;
                margin: 0 12.5%;
                margin-bottom: 5px;
              }
              &.parent-title {
                display: none;
              }
              &.menu-item {
                width: 24.33%;
                margin-left: .33%;
                margin-right: .33%;
                text-align: center;
                float: left;
                a {
                  white-space: normal;
                  line-height: 1.25;
                  padding-left: 5px;
                  padding-right: 5px;
                  color: #fff;
                  font-size: 70%;
                  opacity: 0.65;
                  &:focus,&:hover,&:active {
                    opacity: 1.0;
                  }
                }
                &.current-menu-item {
                  a {
                    opacity: 1.0;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: $screen-sm-min) {
      .navbar-nav {
        position: relative;
        width: 100%;
        & > li {
          position: static;
          .dropdown-toggle .caret {
            display: none;
          }
          &.dropdown:hover > .dropdown-toggle,
          &.dropdown.open > .dropdown-toggle {
            background-image: url('../images/dropdown-selector.png');
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 48px 15px;
          }
          & > .dropdown-menu {
            width: 100%;
            border-radius: 0;
            background-color: $brand-primary;
            padding: $grid-gutter-width;
            border: none;
            text-align: center;
            & > li {
              img.menu-thumb {
                width: 100%;
                height: auto;
                @media (min-width: $screen-sm-min) {
                  margin-bottom: $grid-gutter-width/4;
                }
                @media (min-width: $screen-lg-min) {
                  margin-bottom: $grid-gutter-width/2;
                }
              }
              &.parent-title {
                @media (min-width: $screen-sm-min) {
                  font-size: 22px;
                  text-transform: uppercase;
                  color: #fff;
                  text-align: center;
                  font-weight: 700;
                  margin-bottom: $grid-gutter-width/2;
                }
                @media (min-width: $screen-lg-min) {
                  font-size: 30px;
                  margin-bottom: $grid-gutter-width;
                }
              }
              &.menu-item {
                width: 144px;
                @media (max-width: $screen-md-max) {
                  width: 102px;
                }
                text-align: center;
                display: inline-block;
                vertical-align: text-top;
                a {
                  white-space: normal;
                  line-height: 1.25;
                }
              }
              & > a {
                color: #fff;
                color: fade-out(#fff, 0.2);
                font-size: 14px;
                @media (min-width:$screen-sm-min) and (max-width: $screen-md-max) {
                  font-size: 10px;
                }
                text-transform: uppercase;
                font-weight: 800;
                img.menu-thumb {
                  opacity: 0.6;
                }
                &:hover,
                &:active,
                &:focus {
                  background-color: transparent;
                  color: #fff;
                  img.menu-thumb {
                    opacity: 1.0;
                  }
                }
              }
              &.current-menu-item {
                a {
                  color: #fff;
                  cursor: default;
                  img.menu-thumb {
                    opacity: 1.0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .nav > li.current_page_parent {
      & > a {
      color: $navbar-default-link-active-color;
      }
    }
  }
}
.navbar-default {
  .navbar-nav {
    > li > a:focus {
      color: #000;
    }
  }
}
.dropdown:hover .dropdown-menu {
    display: block;
}
@media (max-width: $screen-xs-max) {
  .dropdown:hover .dropdown-menu {
    position: relative!important;
    margin-bottom: 18px;
  }
  .dropdown-menu {
    & > li {
      a {
        &:focus,
        &:hover,
        &:active {
          background-color: transparent;
        }
      }
    }
  }
}
