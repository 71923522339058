#feature-header {
  text-align: center;
  color: $brand-primary;
  font-weight: 800;
  font-size: 45px;
  @media (max-width: $screen-sm-max) {
    font-size: 32px;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 38px;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
  }
  margin-top: $grid-gutter-width*2;
  margin-bottom: $grid-gutter-width*2;
}

#feature-grid {
  background-color: #e9e9e9;
  .grid-wrapper {
    max-width: 1600px;
    margin: 0 auto;
    @include clearfix();
    background-color: #fff;
  }
  .grid-square {
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    float: left;
    background-color: $brand-primary;
    background-size: cover;
    position: relative;
    background-position: center center;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    &.link {
      &:hover,&:active,&:focus {
        background-color: $brand-primary;
        background-image: none;
      }
      @media (max-width: $screen-xs-max) {
        display: block;
        width: 100%;
        padding-top: $grid-gutter-width*2;
        padding-bottom: $grid-gutter-width*2;
        padding-left: 30px;
        padding-right: 30px;
        height: auto;
        text-align: center;
        border-top: 4px solid #fff;
      }
      a {
        color: #fff;
        font-size: 20px;
        @media (max-width: $screen-xs-max) {
          font-size: 34px;
        }
        @media (max-width: $screen-sm-max) {
          font-size: 20px;
        }
        text-decoration: none;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        @media (min-width: $screen-sm-min) {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        span {
          @media (min-width: $screen-sm-min) {
            @include vertical-align;
            left: 0;
            right: 0;
            padding: 20px;
          }
        }
      }
    }
    &.one {
      background-image: url('../images/grid/one.jpg');
    }
    &.two {
      background-image: url('../images/grid/two.jpg');
    }
    &.three {
      background-image: url('../images/grid/three.jpg');
    }
    &.four {
      background-image: url('../images/grid/four.jpg');
    }
    &.five {
      background-image: url('../images/grid/five.jpg');
    }
    &.six {
      background-image: url('../images/grid/six.jpg');
    }
    &.seven {
      background-image: url('../images/grid/seven.jpg');
    }
    &.eight {
      background-image: url('../images/grid/eight.jpg');
    }
    &.nine {
      background-image: url('../images/grid/nine.jpg');
    }
    &.ten {
      background-image: url('../images/grid/ten.jpg');
    }
  }
}
#mailing-list-desktop {
  background-color: #e9e9e9;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  color: $brand-primary;
  text-align: center;
  font-size: 20px;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
  a {
    border: 3px solid $brand-primary;
    padding: $grid-gutter-width/4 $grid-gutter-width*2;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: $grid-gutter-width/2;
    &:active,
    &:focus,
    &:hover {
      background-color: $brand-primary;
      color: #fff;
      text-decoration: none;
    }
  }
}
#foot {
  padding: $grid-gutter-width*2 0;
  @media (max-width: $screen-xs-max) {
    background-color: #e9e9e9;
    padding: $grid-gutter-width/2 0;
  }
  #menu-footer-navigation {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    list-style: none;
    padding-left: 0;
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    li {
      margin-bottom: $grid-gutter-width/2;
      a {
        font-size: 19px;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
          color: #000;
        }
      }
    }
  }
  #contact-footer {
    @media (min-width: $screen-md-min) {
    margin-left: $grid-gutter-width;
    }
    color: $brand-primary;
    font-size: 12px;
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
  #social-footer {
    width: 100%;
    float: left;
    padding-left: 0;
    list-style: none;
    margin-bottom: $grid-gutter-width;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      margin-bottom: $grid-gutter-width/2;
    }
    @include clearfix();
    .icon {
      display: inline-block;
      &:last-child a {
        margin-right: 0;
      }
      a {
        @include text-hide();
        background-size: 48px 48px;
        width: 48px;
        height: 48px;
        @media (max-width: $screen-xs-max) {
          width: 54px;
          height: 54px;
          background-size: 54px 54px;
        }
        display: block;
        background-repeat: no-repeat;
        margin-right: 16px;
      }
    }
    #facebook-footer a {
      background-image: url('../images/footer-facebook.png');
    }
    #twitter-footer a {
      background-image: url('../images/footer-twitter.png');
    }
    #linkedin-footer a {
      background-image: url('../images/footer-linkedin.png');
    }
    #youtube-footer a {
      background-image: url('../images/footer-youtube.png');
    }
  }
}
#mobile-footer-contact {
  @media (min-width: $screen-sm-min) {
    display: none;
  }
  font-size: 17px;
  margin-bottom: $grid-gutter-width*1.5;
  h3 {
    color: $brand-primary;
    font-weight: 800;
    font-size: 38px;
    text-align: center;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
  }
  .section {
    border-top: 2px solid $brand-primary;
    a {
      padding-top: $grid-gutter-width/2;
      padding-bottom: $grid-gutter-width/2;
      display: block;
      width: 100%;
      text-decoration: none;
      &:hover, &:active, &:focus {
        .icon {
          border-color: #000;
        }
      }
    }
  }
  .search {
    padding-top: $grid-gutter-width;
    .search-form {
      border: 4px solid $brand-primary;
      border-radius: 0;
      @media (max-width: $screen-sm-max) {
        margin-top: 8px;
      }
      .input-group-btn {
        border-right: 5px solid $brand-primary;
      }
      .search-submit {
        color: $brand-primary;
        background-color: #fff;
        border-color: transparent;
        box-shadow: none!important;
        font-size: 26px;
        line-height: 1;
        padding: 6px;
        padding-top: 4px;
        padding-bottom: 3px;
      }
      .search-field {
        border: none;
        box-shadow: none!important;
        color: $brand-primary;
        height: 39px;
        &::-webkit-input-placeholder {
           color: $brand-primary;
        }
        &:-moz-placeholder { /* Firefox 18- */
           color: $brand-primary;
        }
        &::-moz-placeholder {  /* Firefox 19+ */
           color: $brand-primary;
        }
        &:-ms-input-placeholder {
           color: $brand-primary;
        }
        @media (max-width: $screen-sm-max) {
          font-size: 80%;
          padding: 0px 8px;
        }
      }
    }
  }
  .icon {
    border: 4px solid $brand-primary;
    padding: 7px;
    margin-right: 15px;
    font-size: 25px;
    vertical-align: middle;
  }
}
#mobile-foot {
  @media (min-width: $screen-sm-min) {
    display: none;
  }
  a {
    color: #6b6c76;
    display: block;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.5;
    padding: $grid-gutter-width;
    text-decoration: none;
  }
}
#blog-footer {
  background-color: #6b6c76;
  color: #fff;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width*2;
  @media (max-width: $screen-xs-max) {
    padding-bottom: $grid-gutter-width;
  }
  text-align: center;
  h3 {
    text-align: center;
    font-weight: 800;
    color: #fff;
    margin-top: $grid-gutter-width*1;
    margin-bottom: 0;
    font-size: 40px;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
  .view-blog {
    border: 4px solid #fff;
    padding: $grid-gutter-width/4 $grid-gutter-width*2;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-size: 20px;
    @media (max-width: $screen-xs-max) {
      font-size: 29px;
      width: 100%;
    }
    &:active,
    &:focus,
    &:hover {
      background-color: #fff;
      color: #6b6c76;
      text-decoration: none;
    }
  }
  .blog-column {
    text-align: left;
    font-size: 14px;
    padding: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    h3 {
      text-align: left;
      font-size: 19px;
      text-transform: uppercase;
      margin-bottom: 1em;
    }
    a {
      color: #fff;
      text-decoration: none;
      &.read-more {
        display: block;
        margin-top: 1em;
        text-transform: uppercase;
        border-bottom: 1px solid transparent;
        &:hover, &:active, &:focus {
          color: transparentize(#fff, 0.2);
        }
      }
    }
  }
}
