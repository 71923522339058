body {
  @media (max-width: $screen-xs-max) {
    padding-top: 62px;
  }
}
.header_banner {
  background-color: #e9e9e9;
  background-repeat: no-repeat;
  background-position: center center;
  @media (min-width: $screen-sm-min) {
    height: 405px;
    background-size: 1201px 405px;
  }
  @media (min-width: $screen-md-min) {
    height: 540px;
    background-size: 1601px 540px;
  }
  #homeHeader {
    @media (min-width: $screen-sm-min) {
      height: 405px;
    }
    @media (min-width: $screen-md-min) {
      height: 540px;
    }
    width: 100%;
    @media (max-width: $screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: $screen-sm-min) {
      padding-top: $grid-gutter-width*1.75;
    }
    @media (min-width: $screen-md-min) {
      padding-top: $grid-gutter-width*2;
    }
    padding: $grid-gutter-width*1.5;
    .video-header {
      width: 50%;
      font-weight: 300;
      border-bottom: 2px solid #fff;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
      @media (min-width: $screen-sm-min) {
        font-size: 14px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 20px;
      }
      @media (min-width: $screen-sm-min) {
        padding: $grid-gutter-width/2 0;
        padding-bottom: $grid-gutter-width*0.75;
        margin-bottom: $grid-gutter-width*0.75;
      }
      @media (min-width: $screen-md-min) {
        padding: $grid-gutter-width 0;
        padding-bottom: $grid-gutter-width*1.5;
        margin-bottom: $grid-gutter-width;
      }
      a {
        color: #fff;
        background-image: url('../images/play-btn.png');
        background-repeat: no-repeat;
        background-position: left center;
        display: block;
        line-height: 1.2;
        @media (min-width: $screen-sm-min) {
          padding-top: 17px;
          padding-left: 90px;
          height: 80px;
          background-size: 80px 80px;
        }
        @media (min-width: $screen-md-min) {
          padding-top: 17px;
          padding-left: 128px;
          height: 108px;
          background-size: 108px 108px;
        }
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
        span {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 35px;
        }
      }
    }
    .video-header-mobile {
      width: 100%;
      font-weight: 300;
      border-top: 2px solid #fff;
      padding-top: $grid-gutter-width*1.5;
      margin-top: $grid-gutter-width*1.5;
      text-align: center;
      @media (min-width: $screen-sm-min) {
        display: none;
      }
      a {
        color: #fff;
        background-image: url('../images/play-btn.png');
        background-repeat: no-repeat;
        background-position: top center;
        display: block;
        line-height: 1.2;
        padding-top: 130px;
        background-size: 110px 110px;
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
        span {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 29px;
        }
      }
    }
    .intro {
      color: #fff;
      line-height: 1.25;
      font-weight: 300;
      @media (max-width: $screen-xs-max) {
        font-size: 45px;
        text-align: center;
      }
      @media (min-width: $screen-sm-min) {
        font-size: 46px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 64px;
      }
    }
  }
}
.home {
  .main {
    color: $brand-primary;
    margin-top: $grid-gutter-width*2;
    margin-bottom: 0!important;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      font-size: 16px;
      margin-top: $grid-gutter-width*1.25;
      .introduction {
        margin-bottom: $grid-gutter-width*1.5;
      }
    }
    .introduction {
      strong {
        font-weight: 400!important;
      }
    }
    h1 {
      font-size: 45px;
      font-weight: 700;
      text-transform: none!important;
      margin-top: 0!important;
      margin-bottom: $grid-gutter-width;
      @media (max-width: $screen-xs-max) {
        font-size: 38px;
      }
    }
    blockquote {
      border-color: $brand-primary;
      border-width: 4px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
      font-size: 32px;
      font-style: italic;
      line-height: 2.2;
      @media (min-width: $screen-md-min) {
        padding-right: 65px;
      }
      @media (max-width: $screen-md-max) {
        font-size: 30px;
        line-height: 2.08;
      }
      @media (max-width: $screen-xs-max) {
        background-image: url('../images/quote-bg.jpg');
        background-size: cover;
        background-position: center center;
        border-top: 4px solid $brand-primary;
        border-bottom: 4px solid $brand-primary;
        border-left: none;
        background-repeat: no-repeat;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 0;
        text-align: center;
        font-size: 29px;
        line-height: 47px;
        font-weight: 800;
        padding-top: $grid-gutter-width*3.5;
        padding-bottom: $grid-gutter-width*3.5;
      }
    }
    font-size: 22px;
    font-weight: 300;
  }
}

.page-header {
  border-bottom: 4px solid $brand-primary;
  padding-bottom: 0;
  margin-bottom: $grid-gutter-width;
  margin-top: 32px;
  @media (max-width: $screen-xs-max) {
    background-image: url('../images/page-header-mobile.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 -15px;
    padding: $grid-gutter-width*2 0;
    border: none;
    margin-bottom: $grid-gutter-width/2;
  }
  h1 {
    color: #6b6c76;
    font-size: 48px;
    @media (max-width: $screen-md-max) {
      font-size: 40px;
    }
    @media (max-width: $screen-xs-max) {
      margin: 0;
      color: #fff!important;
      text-align: center;
      font-size: 35px;
    }
    text-transform: uppercase;
    font-weight: 800;
  }
}
#page-banner {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
  @include background-image-retina(page-header, jpg, 1601px, 269px);
  background-position: center center;
  background-repeat: no-repeat;
  height: 269px;
  background-color: #e9e9e9;
}
.wrap.container {
  .main {
    margin-bottom: $grid-gutter-width*2;
    font-weight: 300;
    line-height: 1.5625;
    img {
      @include img-responsive();
    }
    h2.about-title
    {
      font-size: 28px;
      margin-top: 22px;
      margin-bottom: 11px;
    }
    h2 {
      text-transform: uppercase;
      margin-top: 2em;
    }
    ul {
      padding-left: 20px;
      li {
        padding-left: 10px;
      }
      ul {
        list-style: disc;
      }
    }
    .lead {
      @media (min-width: $screen-sm-min) {
        font-size: 18px;
        line-height: 1.55555556;
      }
      font-weight: 500;
    }
    .highlight {
      margin-top: $grid-gutter-width*1.5;
      @media (min-width: $screen-sm-min) {
      font-size: 20px;
      }
      color: $brand-primary;
      a {
        text-decoration: underline;
      }
    }
  }
}

.caps {
  font-variant: small-caps;
}

.product-intro-desktop {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
  margin-top: 50px;
}

.product-intro-mobile {
  @media (min-width: $screen-sm-min) {
    display: none;
  }
  border-bottom: 2px solid $gray-lighter;
  padding-bottom: $grid-gutter-width/2;
  margin-bottom: $grid-gutter-width;
}

.product-series {
  border-bottom: 4px solid $brand-primary;
  margin-bottom: $grid-gutter-width;
  padding-bottom: $grid-gutter-width*2;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .divider {
    width: 15%;
    @media (max-width: $screen-sm-max) {
      width: 25%;
    }
    border-bottom: 4px solid $brand-primary;
    margin-bottom: $grid-gutter-width;
  }
  a {
    text-decoration: underline;
  }
  .zoom {
    img {
      height: auto;
      width: 50%;
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
      vertical-align: bottom;
    }
    a {
      text-decoration: none;
      outline : 0;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 85%;
    }
  }
  .gallery-thumbs {
    list-style: none;
    padding-left: 0;
    padding-left: 0!important;
    padding-top: $grid-gutter-width;
    margin: 0 -$grid-gutter-width/4;
    li {
      &.active {
        a {
          border: 4px solid $brand-primary;
        }
      }
      padding: 0 $grid-gutter-width/4;
      display: inline-block;
      width: 20%;
      @media (max-width: $screen-xs-max) {
        width: 31%;
      }
      a {
        display: block;
        border: 4px solid transparent;
        img {
          @include img-responsive();
        }
        &:hover,&:focus,&:active {
          border-color: $brand-primary;
        }
      }
    }
  }
}
.ekko-lightbox {
  .modal-content {
    border-radius: 0!important;
  }
}
.panel-default {
  border-width: 4px;
  font-weight: 400;
  font-size: 115%;
  strong {
    font-weight: 800;
    color: $brand-primary;
  }
  &.two-thirds {
    @media (min-width: $screen-sm-min) {
      width: 66%;
    }
  }
}
.blue {
  color: $brand-primary;
  display: block;
}
.wrap.container {
  .team-member {
    h2 {
      margin-top: 0px;
      small {
        text-transform: none;
        color: $brand-primary;
        font-size: 80%;
        font-weight: 300;
      }
    }
    img {
      @media (max-width: $screen-xs-max) {
        width: 100%;
        height: auto;
      }
    }
    margin-bottom: $grid-gutter-width;
    @media (max-width: $screen-xs-max) {
     margin-bottom: $grid-gutter-width*2;
    }
  }
}

.btn-default {
  border: 4px solid $brand-primary;
  background-color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  color: $brand-primary;
  font-weight: 800;
  padding: 8px 36px;
  font-size: 20px;
  &:hover,&:active,&:focus,&:hover:active {
    border: 4px solid $brand-primary;
    background-color: $brand-primary;
    box-shadow: none;
    color: #fff;
  }
}
.page-template-template-testimonials-php {
  blockquote {
    color: $brand-primary;
    border: none;
    padding-left: 0;
    padding-right: 0;
    cite {
      font-size: 20px;
      color: $brand-primary;
      font-style: normal;
      font-weight: 800;
    }
  }
}
#contact-list {
  color: $brand-primary;
  margin-bottom: 1em;
  padding-left: 0;
  line-height: 1;
  .icon {
    border: 4px solid $brand-primary;
    padding: 7px;
    margin-right: 5px;
    font-size: 25px;
    vertical-align: middle;
  }
}
.hours {
  line-height: 1.6;
}
.vsg-map {
  iframe {
    width: 100%;
    height: 300px;
    margin-bottom: $grid-gutter-width/2;
  }
}
.page-template-template-facility-php,
.page-template-template-contact-php {
  main {
    h1 {
      margin-top: 0px;
      color: #6b6c76;
      text-transform: uppercase;
    }
    .right {
      @media (min-width: $screen-sm-min) {
      margin-top: 46px;
      }
    }
    @media (max-width: $screen-xs-max) {
      .gform_wrapper {
        margin-bottom: $grid-gutter-width*2;
      }
    }
  }
}
.page-template-template-contact-php {
  .main {
    margin-top: 25px;
    @media (min-width: $screen-sm-min) {
      margin-top: 50px;
    }
  }
}

.main {
  .search-form {
    border: 2px solid $brand-primary;
    border-radius: 0;
    margin-top: 12px;
    @media (max-width: $screen-sm-max) {
      margin-top: 8px;
    }
    .search-submit {
      color: $brand-primary;
      background-color: #fff;
      border-color: transparent;
      box-shadow: none!important;
      @media (max-width: $screen-sm-max) {
        padding: 0px 8px;
      }
    }
    .search-field {
      border: none;
      box-shadow: none!important;
      color: $brand-primary;
      &::-webkit-input-placeholder {
         color: $brand-primary;
      }
      &:-moz-placeholder { /* Firefox 18- */
         color: $brand-primary;
      }
      &::-moz-placeholder {  /* Firefox 19+ */
         color: $brand-primary;
      }
      &:-ms-input-placeholder {
         color: $brand-primary;
      }
      @media (max-width: $screen-sm-max) {
        font-size: 80%;
        padding: 0px 8px;
      }
    }
  }
}
.equipment-list {
  .main h2 {
    margin-top: 1em!important;
  }
}

.flex-divider {
  width: 100%;
  border-bottom: 4px solid $brand-primary;
  margin-bottom: $grid-gutter-width;
  margin-top: $grid-gutter-width;
}


.home .row .col-sm-6{
    padding-left:0px;
}
.home .header_banner{
    background-image: url('../images/WIT_Homepage_Header.jpg')!important;
}
.home .main blockquote{
    margin-top: -12px;
}
#page-banner {
    background-image: url('../images/WIT_Subpage_Header.jpg') !important;
    background-size: 1601px 291px;
    height: 291px !important;
}
/* Wire Stripers page */
.zoom img{
    border: #2b3e99 solid 4px;
}
.zoom-link{
    position: relative;
    top: -27px;
    left: 370px;
}
.glyphicon-zoom-in:before{
    content: "" !important;
    background-image: url('../images/zoom-in.png');
    width: 21px;
    height: 21px;
    display: block;
}
/* equipment - our facility */
.comment-form input[type=text]:focus, .comment-form input[type=email]:focus, .comment-form input[type=url]:focus, .comment-form textarea:focus, .form-control:focus, .ginput_container input:focus, .ginput_container select:focus, .ginput_container textarea:focus{
    box-shadow:none !important;
}
.glyphicon-map-marker,
.glyphicon-envelope,
.glyphicon-print,
.glyphicon-earphone{
    top:inherit !important;
}
.media-middle{
    line-height:1.5;
}
/* our standard page*/
.our-standards .main .row .col-sm-6:last-child{
    letter-spacing:0.4px;
    margin-top: -4px;
    line-height: 1.5;
}
/*contact page */
.glyphicon-zoom-in{
    font-size:20px;
    top: 5px !important;
    font-weight:100 !important;
}
/*footer */
#blog-footer .blog-column a.read-more:active,
#blog-footer .blog-column a.read-more:focus,
#blog-footer .blog-column a.read-more:hover {
    color: rgba(255,255,255,1) !important;
    font-weight: bold;
}
/*setting for chrome and safari  */
@media screen and (-webkit-min-device-pixel-ratio:0)
{
    ::i-block-chromem, .our-standards .main .panel-body{
        padding: 15px;
        padding-right: 14px;
        line-height: 1.65;
    }
    .our-standards .main .panel-body{
        padding: 15px;
        padding-right: 14px;
        line-height: 1.65;
    }
    ::i-block-chrome, .our-standards .main .panel-body{
        padding: 15px;
        padding-right: 14px;
        line-height: 1.65;
    }

    /* header submenu */
    ::i-block-chromem, #page-banner {
        margin-top: 32px;
    }
    #page-banner {
        margin-top: 32px;
    }
    ::i-block-chrome, #page-banner {
        margin-top: 32px;
    }
    /* mindset menu hover*/
    ::i-block-chromem, .navbar .navbar-collapse .navbar-nav > li.menu-item-74 > .dropdown-menu {
        padding-bottom: 29px;
    }
    ::i-block-chrome, .navbar .navbar-collapse .navbar-nav > li.menu-item-74 > .dropdown-menu {
        padding-bottom: 29px;
    }
    .navbar .navbar-collapse .navbar-nav > li.menu-item-74 > .dropdown-menu {
        padding-bottom: 29px;
    }
    /* other menu hover*/
    .navbar .navbar-collapse .navbar-nav>li.menu-item-87>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-82>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-71>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-78>.dropdown-menu{
        padding-bottom: 46px !important;
    }
    .navbar .navbar-collapse .navbar-nav>li.menu-item-91>.dropdown-menu{
        padding-bottom:12px !important;
    }
    .navbar .navbar-collapse .navbar-nav>li.menu-item-92>.dropdown-menu{
        padding-bottom:30px !important;
    }
}


.mobile {
    display: none;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-87>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-82>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-74>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-78>.dropdown-menu{
    padding: 30px 253px;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-87>.dropdown-menu>li.menu-item,
.navbar .navbar-collapse .navbar-nav>li.menu-item-82>.dropdown-menu>li.menu-item,
.navbar .navbar-collapse .navbar-nav>li.menu-item-74>.dropdown-menu>li.menu-item,
.navbar .navbar-collapse .navbar-nav>li.menu-item-78>.dropdown-menu>li.menu-item{
    width: 32%;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-74>.dropdown-menu{
    padding-bottom:44px;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-91>.dropdown-menu{
    padding-bottom:10px !important;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-92>.dropdown-menu{
    padding-bottom:29px !important;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-87>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-82>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-71>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-78>.dropdown-menu{
    padding-bottom: 45px !important;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-91>.dropdown-menu,
.navbar .navbar-collapse .navbar-nav>li.menu-item-71>.dropdown-menu{
    padding: 30px 329px;
}
.navbar .navbar-collapse .navbar-nav>li.menu-item-91>.dropdown-menu>li.menu-item,
.navbar .navbar-collapse .navbar-nav>li.menu-item-71>.dropdown-menu>li.menu-item{
    width: 48%;
}
.mobile-search{
    display:none;
}
.home .row .col-sm-6{
    padding-left:0px;
}
.home .header_banner{
    background-image: url('../images/WIT_Homepage_Header.jpg')!important;
}
.home .main blockquote{
    margin-top: -12px;
}
#page-banner {
    background-image: url('../images/WIT_Subpage_Header.jpg') !important;
    background-size: 1601px 291px;
    height: 291px !important;
}
/* Wire Stripers page */
.zoom img{
    border: #2b3e99 solid 4px;
}
.zoom-link{
    position: relative;
    top: -27px;
    left: 370px;
}
.glyphicon-zoom-in:before{
    content: "" !important;
    background-image: url('../images/zoom-in.png');
    width: 21px;
    height: 21px;
    display: block;
}
/* equipment - our facility */
.comment-form input[type=text]:focus, .comment-form input[type=email]:focus, .comment-form input[type=url]:focus, .comment-form textarea:focus, .form-control:focus, .ginput_container input:focus, .ginput_container select:focus, .ginput_container textarea:focus{
    box-shadow:none !important;
}
.glyphicon-map-marker,
.glyphicon-envelope,
.glyphicon-print,
.glyphicon-earphone{
    top:inherit !important;
}
.media-middle{
    line-height:1.5;
}
/* our standard page*/
.our-standards .main .row .col-sm-6:last-child{
    letter-spacing:0.4px;
    margin-top: -4px;
    line-height: 1.5;
}
/*contact page */
.glyphicon-zoom-in{
    font-size:20px;
    top: 5px !important;
    font-weight:100 !important;
}
/*footer */
#blog-footer .blog-column a.read-more:active,
#blog-footer .blog-column a.read-more:focus,
#blog-footer .blog-column a.read-more:hover {
    color: rgba(255,255,255,1) !important;
    font-weight: bold;
}
#foot #social-footer .icon a{
    background-size: cover;
}
/*setting for chrome and safari  */
@media screen and (-webkit-min-device-pixel-ratio:0)
{
    ::i-block-chromem, .our-standards .main .panel-body{
        padding: 15px;
        padding-right: 14px;
        line-height: 1.65;
    }
    .our-standards .main .panel-body{
        padding: 15px;
        padding-right: 14px;
        line-height: 1.65;
    }
    ::i-block-chrome, .our-standards .main .panel-body{
        padding: 15px;
        padding-right: 14px;
        line-height: 1.65;
    }

    /* header submenu */
    ::i-block-chromem, #page-banner {
        margin-top: 32px;
    }
    #page-banner {
        margin-top: 0px !important;
    }
    ::i-block-chrome, #page-banner {
        margin-top: 32px;
    }
    /* mindset menu hover*/
    ::i-block-chromem, .navbar .navbar-collapse .navbar-nav > li.menu-item-74 > .dropdown-menu {
        padding-bottom: 29px;
    }
    ::i-block-chrome, .navbar .navbar-collapse .navbar-nav > li.menu-item-74 > .dropdown-menu {
        padding-bottom: 29px;
    }
    .navbar .navbar-collapse .navbar-nav > li.menu-item-74 > .dropdown-menu {
        padding-bottom: 29px;
    }
    /* other menu hover*/
    .navbar .navbar-collapse .navbar-nav>li.menu-item-87>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-82>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-71>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-78>.dropdown-menu{
        padding-bottom: 46px !important;
    }
    .navbar .navbar-collapse .navbar-nav>li.menu-item-91>.dropdown-menu{
        padding-bottom:12px !important;
    }
    .navbar .navbar-collapse .navbar-nav>li.menu-item-92>.dropdown-menu{
        padding-bottom:30px !important;
    }
}
@media screen and (min-width: 768px)
and (max-width: 991px) {
    .navbar .navbar-collapse .navbar-nav>li.menu-item-74>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-78>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-82>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-87>.dropdown-menu {
        padding: 30px 130px !important;
    }
    body .navbar .navbar-collapse .navbar-nav>li.menu-item-92>.dropdown-menu {
        padding-bottom: 53px!important;
    }
    .navbar .navbar-collapse .navbar-nav>li.menu-item-71>.dropdown-menu,
    .navbar .navbar-collapse .navbar-nav>li.menu-item-91>.dropdown-menu{
        padding: 30px 207px !important;
    }
    .navbar .navbar-collapse .navbar-nav>li.menu-item-74>.dropdown-menu {
        padding-bottom: 46px !important;
    }
}

@media screen and (max-width: 767px){
    .mobile-search{
        display:block;
        margin-top: 10px;
        border-bottom: 1px solid #ffffff;
    }
    body .mobile-search .form-control{
        background-color: #2b3e99 !important;
    }
    body .mobile-search input[type=text]{
        background-color: #2b3e99 !important;
    }
    .mobile-search .search-submit{
        background: none !important;
        color: #ffffff;
        padding: 0px;
    }
    .mobile-search ::-webkit-input-placeholder {
        color: #ffffff !important;
        background-color: none;
        font-size: 12px !important;
    }
    .mobile-search :-moz-placeholder { /* Firefox 18- */
        color: #ffffff;
        background-color: none;
        font-size: 12px !important;
    }
    .mobile-search ::-moz-placeholder {  /* Firefox 19+ */
        color: #ffffff;
        background-color: none;
        font-size: 12px !important;
    }
    .mobile-search :-ms-input-placeholder {
        color: #ffffff;
        background-color: none;
        font-size: 12px !important;
    }
    .search-field{
        background: none;
        color: #ffffff;
        font-size:12px;
    }
    .close-menu{
        display:none;
        background: url("../close-menu.png");
        width:32px;
        height:32px;
    }
    .navbar .navbar-collapse{
        width: 78%;
        margin-left: 22%;
        position: absolute;
        right: 0px;
        float: right !important;
        background-color: rgb(43, 62, 153) !important;
        overflow-x: hidden;

    }
    .navbar .navbar-collapse .navbar-nav .menu-item a {
        font-size: 16px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left:20px;
        color:#ffffff;
    }
    .navbar-fixed-top .navbar-collapse {
        padding-bottom: 25px;
        padding-left: 25px;
    }
    .navbar-toggle img{
        width:32px;
        height:28px;
    }
    .navbar .navbar-collapse .navbar-nav>li>.dropdown-menu>li img.menu-thumb{
        display:none;
    }
    .navbar .navbar-collapse .navbar-nav>li>.dropdown-menu>li.menu-item{
        width:100% !important;
        text-align: left !important;
    }
    .navbar .navbar-collapse .navbar-nav .menu-item .dropdown-menu{
        box-shadow: none !important;
        padding: 0px;
        padding-left: 50px !important;
        padding-bottom: 0px !important;
        padding-top:0px !important;
        padding-right:0px !important;
    }
    body .navbar .navbar-collapse .navbar-nav>li>.dropdown-menu{
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
    .navbar .navbar-collapse .navbar-nav .menu-item .dropdown-menu li a{
        font-size: 12px !important;
        padding:5px !important;
        text-transform: uppercase;
    }
    .bg-blue{
        background-color: #2b3e99 !important;
    }
    body .navbar-default .navbar-toggle:focus,
    body .navbar-default .navbar-toggle:hover{
        background-color: #ffffff;
    }
    /*.navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover{
        background-color: rgb(43, 62, 153) !important;
    }
    .navbar-default .navbar-toggle:focus .icon-bar,
    .navbar-default .navbar-toggle:hover .icon-bar{
        display:none;
    }*/
    /*CONTENT */
    .home .row .col-sm-6{
        padding-left: 15px !important;
    }
    .home .main h2{
        width: 400px;
        text-align: center;
        margin: 0px auto 30px;
    }
    .the-quote{
        width: 320px;
        margin: 0px auto;
    }
    .wrap.container .main .lead {
        font-weight: bold !important;
    }
     .main p:first-child,
     .main p:nth-child(1){
        font-weight: bold !important;
    }
    .nav > li > .dropdown-toggle:before{
        content: '+';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        font-size: 1.7em;
        padding-top: 0.35em;
        opacity: 0.2;
    }
    .nav > li > .dropdown-toggle.active:before{
        content: '-';
        opacity: 1;
        color: #fff;
        position: absolute;
        top: -3px;
    }
    .dropdown-menu {
        display: none !important;
    }
    .navbar .navbar-collapse .nav>li.current_page_parent>a
    {
        color: #FFFFFF !important;
    }
    .navbar .navbar-collapse .nav>li.current_page_parent>a.active
    {
        color: #A73838 !important;
    }
    .dropdown-toggle.active{
        color: #A73838 !important;
    }

    .collapsing{
        display: none !important;
    }
    .collapse.in{

    }
    .dropdown-menu.active {
        display: inherit !important;
        position: relative !important;
        left: auto !important;
        top: auto !important;
        border: none !important;
        float: none !important;
    }

    .intro p{
        width: 400px;
        margin: 0px auto;
    }
    .header_banner{
        background-size: cover !important;
    }


}
@media screen and (max-width: 500px){
    .home .main h2{
        width: 300px;
        font-size: 30px !important;
        margin: 0px auto 30px;
    }
    .home .main blockquote{
        font-size: 24px !important;
    }
    .the-quote{
        width: 260px;
    }
    .intro p{
        width: 250px;
        margin: 0px auto;
        font-size: 32px;
    }
}