.page-template-template-blog-php,
.page-template-template-videos-php,
.page-template-template-press-php,
.search-results,
.single,
.archive {
  .page-header {
    a {
     color: #6B6C76;
      @media (max-width: $screen-xs-max) {
        color: #fff;
      }
    }
    @media (min-width: $screen-sm-min) {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.search-form.posts {
  border-bottom: 1px solid $brand-primary;
  margin-bottom: $grid-gutter-width*2;
  .search-submit {
    color: $brand-primary;
    background-color: #fff;
    border-color: transparent;
    box-shadow: none!important;
    font-size: 16px;
    line-height: 1;
    padding: 6px;
    padding-top: 4px;
    padding-bottom: 3px;
  }
  .search-field.form-control {
    border: none;
    box-shadow: none!important;
    color: $brand-primary;
    height: 39px;
    font-size: 15px;
    &::-webkit-input-placeholder {
       color: $brand-primary;
    }
    &:-moz-placeholder { /* Firefox 18- */
       color: $brand-primary;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
       color: $brand-primary;
    }
    &:-ms-input-placeholder {
       color: $brand-primary;
    }
  }
}

article {
  @media (min-width: $screen-sm-min) {
  border-top: 4px solid $brand-primary;
  }
  @media (max-width: $screen-xs-max) {
    border-bottom: 4px solid $brand-primary;
    padding-bottom: $grid-gutter-width;
    @include clearfix();
  }
  position: relative;
  margin-bottom: $grid-gutter-width;
  .ellipsis {
    color: $brand-primary;
  }
  .entry-title {
    font-style: italic;
    text-transform: none;
    @media (max-width: $screen-xs-max) {
        margin-top: 0!important;
    }
  }
  .byline {
    margin-bottom: $grid-gutter-width;
    a {
      color: #6b6c76;
    }
    .categories a {
      font-weight: 800;
    }
  }
  .readmore {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 90%;
    @media (max-width: $screen-xs-max) {
      font-size: 110%;
      float: left;
      margin-top: 3px;
    }
  }
  .share {
    &.desktop {
      position: absolute;
      top: 0;
      right: 0;
      border: 4px solid $brand-primary;
      border-top: none;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
    &.mobile {
      float: right;
      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
    padding: 5px 8px;
    color: $brand-primary;
    font-style: italic;
    font-size: 12px;
    .share-link {
      &.facebook {
        background-image: url('../images/footer-facebook.png');
      }
      &.twitter {
        background-image: url('../images/footer-twitter.png');
      }
      @include text-hide();
      background-size: 25px 25px;
      width: 25px;
      height: 25px;
      @media (max-width: $screen-xs-max) {
        width: 25px;
        height: 25px;
        background-size: 25px 25px;
      }
      background-repeat: no-repeat;
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.nav-links {
  .page-numbers {
    font-size: 22px;
    line-height: 26px;
    border-radius: 25px;
    font-weight: 800;
    border: 1px solid $brand-primary;
    width: 30px;
    height: 30px;
    padding-top: 0;
    margin-bottom: 0;
    display: inline-block;
    text-align: center;
    vertical-align: center;
    text-decoration: none;
    &.current {
      background-color: $brand-primary;
      color: #fff;
    }
    &.prev,&.next {
      border: none;
      span {
        top: 3px;
      }
    }
  }
}
.oldest {
  font-size: 22px;
  margin-left: 15px;
  display: inline-block;
  color: $brand-primary;
  a {
    text-transform: uppercase;
    text-decoration: underline;
    margin-left: 10px;
    font-weight: 800;
    font-size: 80%;
  }
}
