aside {
  margin-top: 50px;
  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }
  #sub-page-nav {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    margin-bottom: $grid-gutter-width*2;
    list-style: none;
    padding-left: 0;
    border: 4px solid $brand-primary;
    margin-right: $grid-gutter-width*1;
    @media (max-width: $screen-md-max) {
      margin-right: 0;
    }
    .page_item {
      &.current_page_item {
        background-color: $brand-primary;
        a {
          color: #fff;
          font-weight: 700;
          background-image: url('../images/sidebar-caret-active.png');
          background-repeat: no-repeat;
          background-size: 11px 29px;
          background-position: left center;
          &:hover,&:active,&:focus {
            color: #fff;
            background-image: url('../images/sidebar-caret-active.png');
            cursor: default;
          }
        }
      }
      a {
        text-transform: uppercase;
        text-decoration: none;
        padding: 12px 20px;
        padding-right: 6px;
        width: 100%;
        display: block;
        font-size: 20px;
        @media (max-width: $screen-md-max) {
          font-size: 16px;
          padding: 10px 20px;
        }
        border: 0;
        line-height: 1.1;
        font-weight: 300;
        outline : 0;
        &:hover,&:active,&:focus {
          background-image: url('../images/sidebar-caret.png');
          background-repeat: no-repeat;
          background-size: 11px 29px;
          background-position: left center;
          color: $brand-primary;
          font-weight: 700;
        }
      }
    }
  }
}
.widget {
  margin-bottom: $grid-gutter-width;
  h2.widgettitle {
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 1em;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      a {
        color: #6b6c76;
      }
      font-size: 15px;
      margin-bottom: 0.75em;
    }
  }
}
.archive-widgets {
  margin-right: $grid-gutter-width;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
